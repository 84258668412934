import {Component, HostBinding} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {HeaderComponent} from "./core/components/header/header.component";
import {SidebarComponent} from "./core/components/sidebar/sidebar.component";
import {AsyncPipe, NgIf} from "@angular/common";
import {ThemeService} from "./shared/services/theme.service";
import {AuthService} from "@auth0/auth0-angular";
import {LoadingComponent} from "./shared/components/loading/loading.component";
import {environment} from "../environments/environment";
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent, NgIf, LoadingComponent, AsyncPipe, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  @HostBinding('class.dark') get mode() {
    return this.themeService.darkMode();
  }

  constructor(protected themeService: ThemeService, protected authService: AuthService) {
  }

  protected readonly environment = environment;
}
