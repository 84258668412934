<nav class="bg-slate-800 w-full z-40 !border-slate-500 !border-b">
  <div class="flex flex-wrap items-center justify-between mx-auto p-2">
    <div routerLink="/" class="flex items-center space-x-2 cursor-pointer">
      <div class=" p-1 rounded">
        <img src="./favicon.svg" class="h-8" alt="Sysfra Logo">
      </div>
      <span class="self-center text-2xl font-semibold whitespace-nowrap text-white title">Sysfra</span>
    </div>
    <div class="flex space-x-2 items-center justify-center">

      <app-theme-toggle></app-theme-toggle>

      <app-notification-bell></app-notification-bell>

      <ng-container *ngIf="this.user">
        <p-menu  #menu [model]="items" [popup]="true" />

        <div class="flex items-center justify-center h-full w-full cursor-pointer hover:scale-95 transition"  (click)="menu.toggle($event)">
          <p-avatar
            [image]="this.user.picture ?? './assets/avatar.png'"
            styleClass="cursor-pointer scale-125 flex items-center justify-center"
          shape="circle"
          />
          <i class="pi pi-sort-down-fill text-gray-400 mx-2 text-sm"></i>
        </div>

      </ng-container>

    </div>

  </div>
</nav>
