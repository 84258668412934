
<app-feature-header title="Home" description="Grand Overview"></app-feature-header>
<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
    <h2 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-200">System Health</h2>
    <div class="relative pt-1">
      <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
        <div [style.width.%]="getSystemHealth()" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
      </div>
    </div>
    <p class="text-3xl font-bold text-green-500">{{ getSystemHealth().toFixed(0 ) }}%</p>
  </div>
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
    <h2 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-200">Online Nodes</h2>
    <p class="text-3xl font-bold text-blue-500">{{ onlineNodes }}</p>
  </div>
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
    <h2 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-200">Offline Nodes</h2>
    <p class="text-3xl font-bold text-indigo-500">{{ offlineNodes }}</p>
  </div>
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
    <h2 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-200">System Alerts</h2>
    <p class="text-3xl font-bold text-red-500">3</p>
  </div>
</div>

<div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
    <h2 class="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-200">Recent Activities</h2>
    <ul class="divide-y divide-gray-200 dark:divide-gray-700">
      <li *ngFor="let activity of recentActivities" class="py-3 flex items-center">
        <span class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
          <i class="pi {{ activity.icon }} text-gray-500 dark:text-gray-400"></i>
        </span>
        <div class="ml-4">
          <p class="text-sm font-medium text-gray-900 dark:text-gray-100">{{ activity.type }}</p>
          <p class="text-sm text-gray-500 dark:text-gray-400">{{ activity.details }}</p>
        </div>
        <span class="ml-auto text-sm text-gray-500 dark:text-gray-400">{{ activity.timestamp }}</span>
      </li>
    </ul>
  </div>
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
    <h2 class="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-200">Quick Actions</h2>
    <div class="grid grid-cols-2 gap-4">
      <button pButton pRipple label="Add Node" icon="pi pi-plus" class="p-button-rounded p-button-primary"></button>
      <button pButton pRipple label="Run Diagnostics" icon="pi pi-cog" class="p-button-rounded p-button-secondary"></button>
      <button pButton pRipple label="View Logs" icon="pi pi-list" class="p-button-rounded p-button-info"></button>
      <button pButton pRipple label="System Settings" icon="pi pi-sliders-h" class="p-button-rounded p-button-warning"></button>
    </div>
  </div>
</div>

<div class="grid grid-cols-1 gap-6">
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg px-6 pt-6 pb-5">
    <h2 class="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-200">Top 3 Nodes</h2>
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead class="bg-gray-50 dark:bg-gray-700">
        <tr>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Node</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">CPU Usage</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Memory Usage</th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Disk Usage</th>
        </tr>
        </thead>
        <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
        <tr *ngFor="let node of nodeStatuses">
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">{{ node.name }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <span [ngClass]="{'text-green-600': node.status === 'Online', 'text-red-600': node.status === 'Offline', 'text-yellow-600': node.status === 'Warning'}">
                {{ node.status }}
              </span>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{{ node.cpuUsage }}%</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{{ node.memoryUsage }}%</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{{ node.diskUsage }}%</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
