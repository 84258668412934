import { Component, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { CommonModule } from '@angular/common';
import {Ripple} from "primeng/ripple";
import {FeatureHeaderComponent} from "../shared/components/feature-header/feature-header.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TableModule,
    ButtonModule,
    ProgressBarModule,
    Ripple,
    FeatureHeaderComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  onlineNodes: number = 12;
  offlineNodes: number = 10;

  recentActivities: any[] = [
    { id: 1, type: 'Node Added', details: 'Node-03 added', timestamp: '10:30', icon: 'pi-plus-circle' },
    { id: 2, type: 'Alert', details: 'High CPU on Node-01', timestamp: '09:45', icon: 'pi-exclamation-triangle' },
    { id: 3, type: 'Maintenance', details: 'Scheduled maintenance done', timestamp: '22:00', icon: 'pi-cog' },
  ];

  nodePerformanceData: any;

  nodeStatuses: any[] = [
    { name: 'Node-02', status: 'Online', cpuUsage: 72, memoryUsage: 65, diskUsage: 60 },
    { name: 'Node-04', status: 'Offline', cpuUsage: 0, memoryUsage: 0, diskUsage: 45 },
    { name: 'Node-05', status: 'Online', cpuUsage: 45, memoryUsage: 55, diskUsage: 50 },
  ];

  constructor() { }


  getSystemHealth(): number {
    let totalNodes: number = this.onlineNodes + this.offlineNodes;
    return this.onlineNodes / totalNodes * 100;
  }


}
