<p-toast position="bottom-right" />

<div class="flex flex-col h-screen overflow-hidden bg-slate-50 dark:bg-slate-900 dark:text-white">
  <ng-container *ngIf="!environment.production || (authService.isAuthenticated$ | async); else loading">
    <app-header></app-header>

    <div class="flex flex-col-reverse md:flex-row flex-grow overflow-hidden">
      <app-sidebar></app-sidebar>
      <div class="flex-grow flex justify-center overflow-hidden w-full m-1">
        <div class="flex-grow flex justify-center overflow-y-auto">
          <div class="content w-full h-full p-3">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="flex-grow scale-150">
      <app-loading></app-loading>
    </div>
  </ng-template>
</div>
