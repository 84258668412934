import {Component, OnInit} from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {AuthService, User} from "@auth0/auth0-angular";
import {Button} from "primeng/button";
import {MenuModule} from "primeng/menu";
import {MenuItem} from "primeng/api";
import {AvatarModule} from "primeng/avatar";
import {ThemeToggleComponent} from "../../../shared/components/theme-toggle/theme-toggle.component";
import {NotificationBellComponent} from "../../../shared/components/notification-bell/notification-bell.component";
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgIf,
    Button,
    MenuModule,
    AvatarModule,
    ThemeToggleComponent,
    NotificationBellComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{

  items: MenuItem[] | undefined;
  user: User | undefined | null;

  constructor(protected authService: AuthService) {
    this.items = [
      {
        label: 'Feedback',
        icon: 'pi pi-megaphone',
        command: async () => {
          const form = await Sentry.getFeedback()?.createForm()!;
          form.appendToDom();
          form.open();
        }
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
          this.authService.logout();
        }
      }
    ];
  }

  ngOnInit(): void {
    this.authService.user$.subscribe({
      next: user => {
        this.user = user;
      }
    })
  }
}
