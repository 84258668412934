import {Component, effect, HostBinding, signal} from '@angular/core';
import {ToggleButtonModule} from "primeng/togglebutton";
import {FormsModule} from "@angular/forms";
import {ThemeService} from "../../services/theme.service";
import {Button} from "primeng/button";
import {NgSwitch, NgSwitchCase} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'app-theme-toggle',
  standalone: true,
  imports: [
    ToggleButtonModule,
    FormsModule,
    Button,
    NgSwitchCase,
    NgSwitch,
    TooltipModule
  ],
  templateUrl: './theme-toggle.component.html',
  styleUrl: './theme-toggle.component.scss'
})
export class ThemeToggleComponent {

  constructor(protected themeService: ThemeService) {

  }
}
