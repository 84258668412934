<aside class="z-50 md:h-full h-auto !border-t md:!border-t-0 md:!border-b-0 md:!border-r !border-slate-500 text-white" aria-label="Sidebar">
  <p-menu [model]="items" [styleClass]="'h-full px-3 pt-2 overflow-y-auto bg-slate-800 rounded-none flex md:flex-col ' + (this.collapsed ? 'sidebar-collapsed' : 'sidebar-open')">
    <ng-template pTemplate="start">

    </ng-template>

    <ng-template pTemplate="submenuheader" let-item>
      <ng-container *ngIf="item.label">
        <span class="font-bold hidden md:flex">{{ this.collapsed ? "_" : item.label }}</span>
        <span class="md:hidden"> _ </span>
      </ng-container>

    </ng-template>

    <ng-template pTemplate="item" let-item>
      <a [pTooltip]="this.collapsed ? item.tooltip : null" [routerLink]="item.routerLink" routerLinkActive="bg-slate-700" class="flex items-center p-2 rounded-lg text-white font-medium text-xl md:text-lg" [class.justify-center] = "this.collapsed">
        <span  class="text-2xl md:text-xl" [class]="item.icon"></span>
        <span [class.hidden] = "this.collapsed" class="ms-4">{{item.label}}</span>
        <p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />
      </a>
    </ng-template>

    <ng-template pTemplate="end">
      <div class="flex gap-1 flex-row md:flex-col w-full h-full md:w-auto md:h-auto justify-end md:justify-center">

        <a [pTooltip]="this.collapsed? 'Docs (Link)' : ''" href="https://docs.sysfra.com" target="_blank"  class=" p-2 cursor-pointer text-white flex-row hover:scale-95 transition flex items-center justify-center" [class.justify-center] = "this.collapsed">
          <span class="text-2xl md:text-xl pi pi-book" ></span>
          <span [class.hidden] = "this.collapsed" class="ms-4">Docs</span>
        </a>

        <a [pTooltip]="this.collapsed? 'Settings' : ''" routerLink="settings"  routerLinkActive="bg-slate-700" class="p-2 rounded-lg
        cursor-pointer text-white flex-row hover:scale-95 transition flex items-center justify-center" [class.justify-center] = "this.collapsed">
          <span class="text-2xl md:text-xl pi pi-cog" ></span>
          <span [class.hidden] = "this.collapsed" class="ms-4">Settings</span>
        </a>

        <p-button
          (onClick)="toggleCollapse()"
          class="!border-l !md:border-l-0 md:!border-t md:!border-s-0 border-slate-500 w-fit md:w-full ml-2 md:ml-0 mt-0 md:mt-3"
          [icon]="collapsed ? 'text-2xl md:text-xl pi pi-caret-right' : 'text-2xl md:text-xl pi pi-caret-left'"
          [label]="collapsed ? '' : 'Collapse'"
          [text]="true"
        ></p-button>
      </div>


    </ng-template>
  </p-menu>
</aside>
