import { Routes } from '@angular/router';
import {AuthGuard} from "@auth0/auth0-angular";
import {HomeComponent} from "./home/home.component";
import {environment} from "../environments/environment";

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: environment.production ? [AuthGuard] : [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'nodes',
        loadChildren: () =>
          import('./node/node.routes').then((x) => x.NodeRoutes)
      },
      {
        path: 'jobs',
        loadComponent: () =>
          import('./job/job.component').then((x) => x.JobComponent),
      },
      {
        path: 'alerts',
        loadComponent: () =>
          import('./alert/alert.component').then((x) => x.AlertComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('./settings/settings.component').then((x) => x.SettingsComponent),
      }
    ]
  }
];
