import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MenuModule} from "primeng/menu";
import {MenuItem} from "primeng/api";
import {BadgeModule} from "primeng/badge";
import {NgClass, NgIf} from "@angular/common";
import {Button} from "primeng/button";
import {StyleClassModule} from "primeng/styleclass";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    MenuModule,
    BadgeModule,
    NgIf,
    Button,
    StyleClassModule,
    NgClass
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {

  items: MenuItem[] | undefined;
  collapsed: boolean = false;

  ngOnInit() {
    // Retrieve the collapsed state from localStorage
    const storedCollapsedState = localStorage.getItem('sidebarCollapsed');
    this.collapsed = storedCollapsedState === 'true';

    this.items = [
      {
        items: [
          {
            label: 'Home',
            icon: 'pi pi-home',
            tooltip: 'Home',
            routerLink: 'home'
          },
          {
            label: 'Nodes',
            icon: 'pi pi-server',
            tooltip: 'Nodes',
            routerLink: 'nodes'
          },
          {
            label: 'Jobs',
            icon: 'pi pi-hammer',
            tooltip: 'Jobs',
            routerLink: 'jobs'
          },
          {
            label: 'Alerts',
            icon: 'pi pi-bell',
            tooltip: 'Alerts',
            routerLink: 'alerts'
          }
        ]
      },
    ];
  }

  toggleCollapse(){
    this.collapsed = !this.collapsed;
    localStorage.setItem('sidebarCollapsed', this.collapsed.toString());
  }
}
