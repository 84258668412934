import { Component } from '@angular/core';
import {BadgeModule} from "primeng/badge";
import {OverlayPanelModule} from "primeng/overlaypanel";

@Component({
  selector: 'app-notification-bell',
  standalone: true,
  imports: [
    BadgeModule,
    OverlayPanelModule
  ],
  templateUrl: './notification-bell.component.html',
  styleUrl: './notification-bell.component.scss'
})
export class NotificationBellComponent {

}
