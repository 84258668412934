import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: environment.production ? "https://c80447f8501c9fe534db83b79adc5d1e@o974330.ingest.us.sentry.io/4507599930589184" : "",
  integrations: [
    Sentry.feedbackIntegration({
      autoInject: false,
      themeLight: {
        background: "#1E293B",
      },
      themeDark: {
        background: "#1E293B",
      },
      triggerLabel: "Feedback",
      formTitle: "Feedback",
      submitButtonLabel: "Send",
      messagePlaceholder: "Enter feedback here"
    }),
  ],
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
